define("ember-intl/macros/intl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.__intlInjectionName = void 0;
  _exports.default = intl;

  /**
   * @private
   * @hide
   */
  var __intlInjectionName = "intl-".concat(Date.now().toString(36));

  _exports.__intlInjectionName = __intlInjectionName;

  function intl() {
    var _Ember;

    for (var _len = arguments.length, dependentKeysAndGetterFn = new Array(_len), _key = 0; _key < _len; _key++) {
      dependentKeysAndGetterFn[_key] = arguments[_key];
    }

    var getterFn = dependentKeysAndGetterFn.pop();
    var dependentKeys = dependentKeysAndGetterFn;
    return (_Ember = Ember).computed.apply(_Ember, ["".concat(__intlInjectionName, ".locale")].concat(dependentKeys, [function (propertyKey) {
      if (!Ember.get(this, __intlInjectionName)) {
        Ember.defineProperty(this, __intlInjectionName, {
          value: Ember.getOwner(this).lookup('service:intl'),
          enumerable: false
        });
      }

      var intl = Ember.get(this, __intlInjectionName);
      return getterFn.call(this, intl, propertyKey, this);
    }]));
  }
});