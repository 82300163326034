define("ember-tracker/mixins/google-analytics-route", ["exports", "ember-tracker/-privates/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
     * Add the Google Analytics services to each route so it's available.
     * @public
     * @memberOf {GoogleAnalyticsRoute}
     * @type {Service.GoogleAnalytics}
     */
    _ga: Ember.inject.service('google-analytics'),

    /**
     * Watches the didTransition event so we can update analytics.
     * @public
     * @observes {didTransition}
     * @memberOf {GoogleAnalyticsRoute}
     * @type {Function}
     */
    _etPageView: Ember.on('didTransition', function () {
      var routeName = this.get('currentRouteName'),
          route = this._etGetCurrentRoute(routeName),
          ga = this.get('_ga');

      var applyArgs = [this.get('url'), getTitle(route)];

      if (Ember.typeOf(route.beforeAnalyticsPageview) === 'function') {
        var changes = route.beforeAnalyticsPageview(ga);

        if (changes) {
          if (changes.page) {
            applyArgs[0] = changes.page;
          }

          if (changes.title) {
            applyArgs[1] = changes.title;
          }

          if (changes.options) {
            applyArgs[2] = changes.options;
          }
        }
      }

      ga.pageview.apply(ga, applyArgs);
    }),

    /**
     * Returns the route required.
     * @private
     * @memberOf {GoogleAnalyticsRoute}
     * @return {Route}
     */
    _etGetCurrentRoute: function _etGetCurrentRoute(routeName) {
      return (0, _utils.getCurrentRoute)(this, routeName);
    }
  });
  /**
   * Returns the page title either by looking at the route or grabbing it from the dom.
   * @public
   * @memberOf {GoogleAnalyticsRoute}
   * @param {Ember.Route} route
   * @return {String}
   */


  _exports.default = _default;

  function getTitle(route) {
    return route.get('title') || _utils.IN_BROWSER && document && document.title || '';
  }
});