define("ember-bootstrap/mixins/component-parent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mixin for components that act as a parent component in a parent-child relationship of components
   *
   * @class ComponentParent
   * @namespace Mixins
   * @private
   */
  var _default = Ember.Mixin.create({
    // eslint-disable-line ember/no-new-mixins

    /**
     * Array of registered child components
     *
     * @property children
     * @type array
     * @protected
     */
    children: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('children', Ember.A());
    },

    /**
     * Register a component as a child of this parent
     *
     * @method registerChild
     * @param child
     * @public
     */
    registerChild: function registerChild(child) {
      Ember.run.schedule('actions', this, function () {
        this.get('children').addObject(child);
      });
    },

    /**
     * Remove the child component from this parent component
     *
     * @method removeChild
     * @param child
     * @public
     */
    removeChild: function removeChild(child) {
      Ember.run.schedule('actions', this, function () {
        this.get('children').removeObject(child);
      });
    }
  });

  _exports.default = _default;
});